@font-face {
  font-family: 'customFont';
  src: local('customFont'), url(./fonts/BlackthornsDemoBlack-L2GE.ttf) format('truetype');
}

/* @font-face {
  font-family: 'customFont';
  src: local('customFont'), url(./fonts/MandaloreExpanded-1GGd4.otf) format('opentype');
} */

/*----------------------------------------------------------------------------- DOCUMENT */
* { box-sizing: border-box; margin: 0; padding:0; }
body { font-family: Arial, Helvetica, sans-serif; line-height: 1.4;}
a { color: #333; text-decoration: none; }


/*----------------------------------------------------------------------------- Menu */
nav .brand-logo {font-family: 'customFont';}
ul.MainMenu {
  position: absolute;
  right: 0px;
  z-index: 1000000;
}


.dropdown-content {
    position: relative;
}



/*----------------------------------------------------------------------------- FORMS */
textarea {
    background-color: #f8f8f8;
    height: 100px;
    margin: 15px 0;
    border: none;
    padding: 20px;
    border-radius: 5px;
    width:100%;
}

input {
    background-color: #f8f8f8!important;
    margin: 9px 0!important;
    border: none!important;
    padding: 4px 15px!important;
    border-radius: 5px!important;
}


  /* label underline focus color */
  input[type=text]:focus {
    border-bottom: 1px solid #2196F3!important;
    box-shadow: 0 1px 0 0 #2196F3!important;
  }

  .input-field .prefix {
    padding-top: 12px;
    padding-left: 10px;
    z-index: 100;
  }

  .input-field>label {
    padding-top: 0;
    padding-left: 0;
    z-index: 100;
    color: #000000;
    position: initial;
}

  .InputIcons {
    padding-left: 47px!important;
    box-sizing: border-box!important;
  }

  /* icon prefix focus color */
  .input-field .prefix.active {
    color: #2196F3;
  }

 
  
  .input-field .prefix ~ input, .input-field .prefix ~ textarea, .input-field .prefix ~ label, .input-field .prefix ~ .validate ~ label, .input-field .prefix ~ .helper-text, .input-field .prefix ~ .autocomplete-content {
    width: 100%;
}





/*----------------------------------------------------------------------------- CLEARFIX */
.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}

/**
* For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats.
*/
.cf {
  *zoom: 1;
}


 /* icon prefix focus color */
 button:focus {background-color: #2196F3!important;}
 .btn:hover {opacity: .5;}



/*----------------------------------------------------------------------------- HOME PAGE */
.icon-container {
  margin-top: 20px;
}

img.tech-icon {
  margin: 5px;
}


.panel-Image {
  width:100%;
  border-radius: 5px;
}

.homepage {
  background-color: #f6f6f6;
}

.aboutSection {
  margin-bottom: 40px;
}

.project-box {
  padding: 35px;
  background-color: #ffffff;
  color: #444444;
  margin: 3px;
  border-radius: 5px;
  box-shadow: #0000000d 6px 5px 11px;
}


.project-box h2 {
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  font-family: customFont;
}

.project-box p {
  margin-top: 25px;
}

a.brand-logo.center {
  width: 100%;
}



/*----------------------------------------------------------------------------- HOME PAGE */

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100000;
}


footer a {
  color: #fff;
  text-decoration: none;
}