@font-face {
  font-family: 'customFont';
  src: local('customFont'), url(/static/media/BlackthornsDemoBlack-L2GE.0adc542d.ttf) format('truetype');
}

/* @font-face {
  font-family: 'customFont';
  src: local('customFont'), url(./fonts/MandaloreExpanded-1GGd4.otf) format('opentype');
} */

/*----------------------------------------------------------------------------- DOCUMENT */
* { box-sizing: border-box; margin: 0; padding:0; }
body { font-family: Arial, Helvetica, sans-serif; line-height: 1.4;}
a { color: #333; text-decoration: none; }


/*----------------------------------------------------------------------------- Menu */
nav .brand-logo {font-family: 'customFont';}
ul.MainMenu {
  position: absolute;
  right: 0px;
  z-index: 1000000;
}


.dropdown-content {
    position: relative;
}



/*----------------------------------------------------------------------------- FORMS */
textarea {
    background-color: #f8f8f8;
    height: 100px;
    margin: 15px 0;
    border: none;
    padding: 20px;
    border-radius: 5px;
    width:100%;
}

input {
    background-color: #f8f8f8!important;
    margin: 9px 0!important;
    border: none!important;
    padding: 4px 15px!important;
    border-radius: 5px!important;
}


  /* label underline focus color */
  input[type=text]:focus {
    border-bottom: 1px solid #2196F3!important;
    box-shadow: 0 1px 0 0 #2196F3!important;
  }

  .input-field .prefix {
    padding-top: 12px;
    padding-left: 10px;
    z-index: 100;
  }

  .input-field>label {
    padding-top: 0;
    padding-left: 0;
    z-index: 100;
    color: #000000;
    position: static;
    position: initial;
}

  .InputIcons {
    padding-left: 47px!important;
    box-sizing: border-box!important;
  }

  /* icon prefix focus color */
  .input-field .prefix.active {
    color: #2196F3;
  }

 
  
  .input-field .prefix ~ input, .input-field .prefix ~ textarea, .input-field .prefix ~ label, .input-field .prefix ~ .validate ~ label, .input-field .prefix ~ .helper-text, .input-field .prefix ~ .autocomplete-content {
    width: 100%;
}





/*----------------------------------------------------------------------------- CLEARFIX */
.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}

/**
* For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats.
*/
.cf {
  *zoom: 1;
}


 /* icon prefix focus color */
 button:focus {background-color: #2196F3!important;}
 .btn:hover {opacity: .5;}



/*----------------------------------------------------------------------------- HOME PAGE */
.icon-container {
  margin-top: 20px;
}

img.tech-icon {
  margin: 5px;
}


.panel-Image {
  width:100%;
  border-radius: 5px;
}

.homepage {
  background-color: #f6f6f6;
}

.aboutSection {
  margin-bottom: 40px;
}

.project-box {
  padding: 35px;
  background-color: #ffffff;
  color: #444444;
  margin: 3px;
  border-radius: 5px;
  box-shadow: #0000000d 6px 5px 11px;
}


.project-box h2 {
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  font-family: customFont;
}

.project-box p {
  margin-top: 25px;
}

a.brand-logo.center {
  width: 100%;
}



/*----------------------------------------------------------------------------- HOME PAGE */

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100000;
}


footer a {
  color: #fff;
  text-decoration: none;
}
.red-btn {background-color: #d10d0d}
.green-btn {background-color: #069341}
.yellow-btn {background-color: #cfae00}


.todoItemUI {float:right}

.todoItemData {
    width: 80%;
    float: left;
}

p.todoNotes  {color:#67696b;}
.todoTitle {font-size:1.1em}

.btnStyle {
    color:#fff;
    border: none;
    padding: 1px 3px;
    margin-top: -5px;
    float: left;
    cursor: pointer;
    border-radius: 50%;
    margin-right: 5px;
}

.todoForm .cancelBTN {
    float: right;
    margin-bottom: 10px;
    display: inline-block;
    background: red;
    padding: 6px;
    color: white;
}

form#todoForm {
    padding-bottom: 33px;
}

.TodoInput {
    padding: 5px 10px  !important;
    margin-bottom: 5px !important;
    border-right: 2px solid #b4b4b4 !important;
    border-radius: 5px 0px 0 5px!important;
  }

  .TimeInput {
    padding: 5px 10px !important;
    margin-bottom: 5px !important;
    border-radius: 0!important;
  }

  .AddTodoBtn {
    padding: 3px 15px!important;
    margin-top: 9px;
  }
  
  .todoForm .input-field {width: auto;}

.todoItem input[type="checkbox"] {display:block!important; opacity: 1!important; margin-top: 2px!important; pointer-events: all;}
.todoItem i {margin-left:20px;}
.todoItem button i {margin-left:0px;}
.todoItem button:hover {opacity: .8;}


.InputIcons {
    padding-left: 47px!important;
    box-sizing: border-box!important;
}


.todoMenu {
    background-color: #f8f8f8;
    width: 100%;
    padding: 10px;
    border-top: 5px solid #344a75;
    background-color: #f1f0f0;
}


.todoMenu button {
    margin-top: 11px;
    height: 40px!important;
    padding: 0 15px;
}

input.todo_listName {
    background-color: white!important;
    height: 40px!important;
    text-align: center;
}

.todoMenu button:focus {
    background-color: darkgray!important;
    border: 1px solid #1b1b1b;
}
.todoMenu button i{pointer-events: none;}

.input-field {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
}

.todoMenu select {
    width: 100%;
    margin-top: 11px;
    background-color: white!important;
    display: inline-block;
    padding-left: 50px!important;
    /* float: left; */
}

.todoMenu .input-field {
    width: 100%;
    display: inline-block;
}

input.todo_listName {
    width: 75%!important;
}


/* MOBILE CSS */
@media only screen and (min-width: 701px) {
    .mobile {display:none;}
    .desktop {display:block;}
}


@media only screen and (max-width: 700px) {
    .mobile {display:block;}
    .desktop {display:none;}

    .row { margin-bottom: 0px;    }
    .todoMenu.mobile {padding-bottom: 25px; margin-bottom:25px;}

    input.todo_listName {width: 70%!important;}
    .todoMenu button {padding: 0 5px; }

    .todoItemUI {
        display: table-cell;
        padding-top: 5px;
        width: 35px!important;
    }

    .todoItemData { width: 85%;  float: left; }
    .todoItemUI button {margin:0; margin-bottom: 9px;}

    .react-datepicker-popper {
        position: fixed!important;
        will-change: transform!important;
        z-index: 1000000!important;
        top: 45px!important;
        left: 5%!important;
        -webkit-transform: translate3d(0px, 0, 0px)!important;
                transform: translate3d(0px, 0, 0px)!important;
    }

    .cfm:before,
    .cfm:after {
        content: " "; /* 1 */
        display: table; /* 2 */
        }

    .cfm:after {
    clear: both;
    }

    /**
    * For IE 6/7 only
    * Include this rule to trigger hasLayout and contain floats.
    */
    .cfm {
    *zoom: 1;
    }

}



.weatherApp {
    height: 100vh;
    background-size: cover;
    background-position: bottom center;
}

#noData {color:white; text-align:center;}

.weatherApp main {
    background: #00000061;
    width: 100%;
    height: 100vh;
    
}


.weather-data {color:white; text-align:center;}

.Country {
    font-size: 3em;
    font-weight: 900;
    text-shadow: #000000c7 2px 2px 3px;
}

.Date {
    font-size: 1.5em;
    text-shadow: #000000c7 1px 1px 3px;
}

.main-temp {
    font-size: 6em;
    font-weight: bold;
    text-shadow: #000000c7 2px 2px 3px;
}

.temp {
    background: #ffffffc9;
    display: inline-block;
    padding: 15px;
    border-radius: 20px;
    color: black;
    font-size: 1.3em;
}

input.search-bar {
    padding-left: 50px!important;
}

.gallery-user {
    padding:10px;
    color: white;
}
.gallery-user a {
    color: white;
    position: absolute;
    top: 12px;
}
