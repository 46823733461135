.red-btn {background-color: #d10d0d}
.green-btn {background-color: #069341}
.yellow-btn {background-color: #cfae00}


.todoItemUI {float:right}

.todoItemData {
    width: 80%;
    float: left;
}

p.todoNotes  {color:#67696b;}
.todoTitle {font-size:1.1em}

.btnStyle {
    color:#fff;
    border: none;
    padding: 1px 3px;
    margin-top: -5px;
    float: left;
    cursor: pointer;
    border-radius: 50%;
    margin-right: 5px;
}

.todoForm .cancelBTN {
    float: right;
    margin-bottom: 10px;
    display: inline-block;
    background: red;
    padding: 6px;
    color: white;
}

form#todoForm {
    padding-bottom: 33px;
}

.TodoInput {
    padding: 5px 10px  !important;
    margin-bottom: 5px !important;
    border-right: 2px solid #b4b4b4 !important;
    border-radius: 5px 0px 0 5px!important;
  }

  .TimeInput {
    padding: 5px 10px !important;
    margin-bottom: 5px !important;
    border-radius: 0!important;
  }

  .AddTodoBtn {
    padding: 3px 15px!important;
    margin-top: 9px;
  }
  
  .todoForm .input-field {width: auto;}

.todoItem input[type="checkbox"] {display:block!important; opacity: 1!important; margin-top: 2px!important; pointer-events: all;}
.todoItem i {margin-left:20px;}
.todoItem button i {margin-left:0px;}
.todoItem button:hover {opacity: .8;}


.InputIcons {
    padding-left: 47px!important;
    box-sizing: border-box!important;
}


.todoMenu {
    background-color: #f8f8f8;
    width: 100%;
    padding: 10px;
    border-top: 5px solid #344a75;
    background-color: #f1f0f0;
}


.todoMenu button {
    margin-top: 11px;
    height: 40px!important;
    padding: 0 15px;
}

input.todo_listName {
    background-color: white!important;
    height: 40px!important;
    text-align: center;
}

.todoMenu button:focus {
    background-color: darkgray!important;
    border: 1px solid #1b1b1b;
}
.todoMenu button i{pointer-events: none;}

.input-field {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
}

.todoMenu select {
    width: 100%;
    margin-top: 11px;
    background-color: white!important;
    display: inline-block;
    padding-left: 50px!important;
    /* float: left; */
}

.todoMenu .input-field {
    width: 100%;
    display: inline-block;
}

input.todo_listName {
    width: 75%!important;
}


/* MOBILE CSS */
@media only screen and (min-width: 701px) {
    .mobile {display:none;}
    .desktop {display:block;}
}


@media only screen and (max-width: 700px) {
    .mobile {display:block;}
    .desktop {display:none;}

    .row { margin-bottom: 0px;    }
    .todoMenu.mobile {padding-bottom: 25px; margin-bottom:25px;}

    input.todo_listName {width: 70%!important;}
    .todoMenu button {padding: 0 5px; }

    .todoItemUI {
        display: table-cell;
        padding-top: 5px;
        width: 35px!important;
    }

    .todoItemData { width: 85%;  float: left; }
    .todoItemUI button {margin:0; margin-bottom: 9px;}

    .react-datepicker-popper {
        position: fixed!important;
        will-change: transform!important;
        z-index: 1000000!important;
        top: 45px!important;
        left: 5%!important;
        transform: translate3d(0px, 0, 0px)!important;
    }

    .cfm:before,
    .cfm:after {
        content: " "; /* 1 */
        display: table; /* 2 */
        }

    .cfm:after {
    clear: both;
    }

    /**
    * For IE 6/7 only
    * Include this rule to trigger hasLayout and contain floats.
    */
    .cfm {
    *zoom: 1;
    }

}


