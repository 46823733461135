.weatherApp {
    height: 100vh;
    background-size: cover;
    background-position: bottom center;
}

#noData {color:white; text-align:center;}

.weatherApp main {
    background: #00000061;
    width: 100%;
    height: 100vh;
    
}


.weather-data {color:white; text-align:center;}

.Country {
    font-size: 3em;
    font-weight: 900;
    text-shadow: #000000c7 2px 2px 3px;
}

.Date {
    font-size: 1.5em;
    text-shadow: #000000c7 1px 1px 3px;
}

.main-temp {
    font-size: 6em;
    font-weight: bold;
    text-shadow: #000000c7 2px 2px 3px;
}

.temp {
    background: #ffffffc9;
    display: inline-block;
    padding: 15px;
    border-radius: 20px;
    color: black;
    font-size: 1.3em;
}

input.search-bar {
    padding-left: 50px!important;
}
